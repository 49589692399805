// Override default variables before the import
$body-bg: #000;

@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lilita One', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.video-background {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.video-foreground,
.video-foreground video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
}
.navbar-brand {
  img {
    width: 130px;
  }
}
